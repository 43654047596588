.services-list-wrapper {
    position: relative;
}

.services-list-container {
    display: flex;
    padding: 64px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.services-list-filter-container {
    display: flex;
    padding: 8px 8px;
    align-items: center;
    gap: 16px;
}

.services-list-filter-button {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.services-page-options-container {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 56px;
    align-self: stretch;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    z-index: 2;
}

.services-service-card {
    display: flex;
    /* width: 360px;
    height: 408px; */
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.services-service-card-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.services-service-card-description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
}

.services-service-card-type-container {
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.services-service-card-icon {
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
}

.services-service-card-name-container {
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.services-service-card-cta-button {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}