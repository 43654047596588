.terms-and-conditions-landing-container {
    display: flex;
    padding: 64px 120px;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    align-self: stretch;
}

.terms-and-conditions-landing-heading-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.terms-and-conditions-landing-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}