.credentials-testimonial-wrapper {
    position: relative;
}

.credentials-testimonial-container {
    display: flex;
    padding: 64px 120px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.credentials-testimonial-section-heading-container {
    display: flex;
    padding: 64px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    z-index: 2;
}

.credentials-testimonial-section-heading-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.credentials-testimonial-section-heading-wa-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.credentials-testimonial-section-heading-wa-icon {
    width: 56px;
    height: 56px;
}

.credentials-testimonial-section-heading-wa-button {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.credentials-testimonial-carousel-container {
    display: flex;
    flex: 1 0 46%;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    z-index: 2;
    height: 100%;
}

.credentials-testimonial-carousel-dots-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}

.credentials-testimonial-carousel-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.credentials-testimonial-testimonial-container {
    position:relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
}

.credentials-testimonial-testimonial-item {
    position: absolute;
    top: 0px;
    width:100%;
}

.fade-in-out {
    opacity: 0;
}

.fade-in-out.right {
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 0.4s ease-out, transform 0.5s ease-out;
}

.fade-in-out.left {
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.4s ease-out, transform 0.5s ease-out;
}
  
.fade-in-out.active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.4s linear, transform 0.5s linear;
}

.fade-in-out.slide-out {
    opacity: 0;
}

.fade-in-out.slide-out.right {
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.4s ease-out, transform 0.5s ease-out;
}

.slide-in-out.slide-out.left {
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 0.4s ease-out, transform 0.5s ease-out;
}
