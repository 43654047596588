.landing-container {
    display: flex;
    padding: 64px 120px;
    align-items: center;
}

.landing-section-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
}

.landing-graphic {
    width: 500px;
    height: 500px;
}

.landing-search-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    position: relative;
}

.landing-search-bar {
    display: flex;
    padding: 16px 16px 16px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.search-button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
}

.search-text {
    text-align: center;
}