.credentials-container {
    display: flex;
    padding: 64px 120px;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
}

.credentials-section-heading-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    flex-shrink: 1;
    z-index: 1;
    max-width: 45%;
}

.credentials-section-heading-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.credentials-section-heading-button {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.credentials-statistics-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
    z-index: 1;
}

.credentials-statistics-column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 64px;
}

.credentials-statistics-row-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.credentials-statistics-row-icon {
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
}

.credentials-statistics-row-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}