.testimonial-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
}

.home-testimonial-wave {
    width: 100%;
    /* display: flex; */
    position: absolute;
    top: 0px;
}

.testimonial-content-container {
    display: flex;
    padding: 64px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
    /* min-height: 410px; */
    z-index: 1;
}

.testimonial-section-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.testimonial-testimony-container {
    display: flex;
    padding: 0px 80px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.testimonial-testimony-prompt {
    display: flex;
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    align-self: stretch;
}

.testimonial-testimony-prompt-child {
    width: 45%;
}

.testimonial-testimony-replies {
    display: flex;
    height: 129px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    overflow: hidden;
    position: relative;
}

.testimonial-testimony-replies-content {
    display: flex;
    flex-direction: column;
    animation: scroll 15s linear infinite;
    width: 100%;
}

.testimonial-testimony-replies:hover .testimonial-testimony-replies-content {
    animation-play-state: paused;
}

.testimonial-testimony-replies-child {
    /* background-image: url("home-testimonial-replies.svg") repeat-y; */
    width: 45%;
    /* height: 1299px; */
    /* z-index: 1; */
}

@keyframes scroll {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }
    5% {
        transform: translateY(5%);
        opacity: 0.5;
    }
    10% {
        transform: translateY(0%);
        opacity: 1;
    }
    90% {
        transform: translateY(-90%);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.testimonial-carousel-container {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    z-index: 5;

    /* top: 0px; */
}

.testimonial-carousel-user-container {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.testimonial-carousel-user-avatar {
    width: 60px;
    height: 60px;
    background: url('../../assets/graphic-placeholder.png'), lightgray 50% / cover no-repeat;
}

.testimonial-carousel-user-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.testimonial-carousel-text-container {
    width: 800px;
}

.testimonial-carousel-nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.testimonial-carousel-nav-button {
    width: 24px;
    height: 24px;
}