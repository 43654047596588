.splash-page-landing-container {
    display: flex;
    padding: 64px 120px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.splash-page-landing-heading-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.splash-page-landing-heading-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}