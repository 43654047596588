.content-container {
    display: flex;
    padding: 64px 120px 0px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.content-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.content-header-wa-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.content-header-wa-icon {
    width: 56px;
    height: 56px;
}

.content-header-wa-button {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.content-steps-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
}

.content-steps-3-box-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 64px;
    align-self: stretch;
}

.content-steps-2-box-container {
    display: flex;
    padding: 0px 80px;
    align-items: stretch;
    gap: 64px;
    align-self: stretch;
}

.content-steps-box {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.content-step-box-heading-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.content-step-box-heading-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-step-box-heading-icon {
    flex-shrink: 0;
}

.content-steps-box-numbering-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}

