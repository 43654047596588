.credentials-papers-container {
    display: flex;
    /* height: 790px; */
    padding: 64px 120px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.credentials-papers-text-and-carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.credentials-papers-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.credentials-papers-carousel-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.credentials-papers-active-paper-container {
    display: flex;
    /* flex: 1 0 44.17%; */
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.credentials-papers-image-container {
    /* display: flex; */
    position: relative;
    overflow-x: hidden;
}

.credentials-papers-image {
    position: absolute;
    top: 50%;
    width:100%;
    max-height: 100%;
}

.slide-in-out {
    opacity: 0;
    transform: translate(0%, -50%);
    /* transition: opacity 0.25s ease-out, transform 0.5s ease-out; */
}

.slide-in-out.right {
  opacity: 0;
  transform: translate(50%, -50%);
  transition: opacity 0.25s ease-out, transform 0.5s ease-out;
}

.slide-in-out.left {
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-out, transform 0.5s ease-out;
}

.slide-in-out.active {
  opacity: 1;
  transform: translate(0%, -50%);
  transition: opacity 0.25s linear, transform 0.5s linear;
}


.slide-in-out.slide-out {
    opacity: 0;
    transform: translate(0%, -50%);
    /* transition: opacity 0.25s ease-out, transform 0.5s ease-out; */
}

.slide-in-out.slide-out.right {
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-out, transform 0.5s ease-out;
}

.slide-in-out.slide-out.left {
    opacity: 0;
    transform: translate(50%, -50%);
    transition: opacity 0.25s ease-out, transform 0.5s ease-out;
}