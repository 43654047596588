.mastery-container {
    display: flex;
    padding: 64px 120px;
    align-items: center;
    gap: 120px;
    /* overflow-x: hidden; */
}

.mastery-section-heading-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
}

.mastery-section-heading-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.mastery-section-heading-primary-button {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.mastery-image {
    width: 55%;
    height: 360px;
    flex-shrink: 0;
}