.blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
    min-height: 200px;
}

.blog-content-container {
    display: flex;
    padding: 64px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
    position: absolute;
    top: 0px;
    z-index: 2;
}

.blog-section-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.blog-articles-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.blog-articles-cards-container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    /* grid-gap: 56px; */
    display: flex;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.blog-article-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 80%;
    overflow-x: visible;
}

.blog-article-card-content {
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    position: relative;
    z-index: 1;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
}

.blog-inner-article-card {
    display: flex;
    /* max-width: 80%; */
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: absolute;
    top: -160px;
}

.blog-inner-article-card-text-container {
    display: flex;
    /* width: 256px;
    height: 96px; */
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.blog-inner-article-card-secondary-button {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}