.big-height {
    height: 1000px;
    width: 100%;
}

.credentials-language-container {
    display: flex;
    padding: 0px 120px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 16px;
}

.credentials-language-certificates-container {
    display: flex;
    box-sizing: border-box;
    width: 53%;
    /* flex: 1 0 44.44%; */
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.credentials-left-cert-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    /* flex: 1 0 50%; */
}

.credentials-right-cert-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    /* flex: 1 0 50%; */
}

.credentials-language-text-container {
    display: flex;
    box-sizing: border-box;
    width: 37%;
    /* flex: 1 0 30.69%; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

  
.scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}
  
.scroller[data-animated="true"] {
    overflow: hidden;
    /* -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent); */
}
  
.scroller[data-animated="true"] .scroller__inner {
    height: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
}
  
.scroller[data-direction="down"] {
    --_animation-direction: reverse;
}
  
.scroller[data-direction="up"] {
    --_animation-direction: forwards;
}
  
.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}
  
.scroller[data-speed="slow"] {
    --_animation-duration: 60s;
}

.scroller:hover .scroller__inner {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateY(calc(50% + 0.5rem));
        opacity: 0;
    }
    10% {
        transform: translateY(calc(40% + 0.4rem));
        opacity: 1;
    }
    90% {
        transform: translateY(calc(-40% - 0.4rem));
        opacity: 1;
    }
    100% {
        transform: translateY(calc(-50% - 0.5rem));
        opacity: 0;
    }
}



.tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
}
  
.tag-list Image {
    padding: 1rem;
    background: var(--clr-primary-400);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

.carousel-card {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}