.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
    /* width: 100%; */
}

.footer-wave-container {
    display: flex;
    z-index: 1;
    width: 100%;
}

.footer-nav-wrapper {
    width: 100%;
    display: flex;
    /* position: absolute;
    top: 54px;
    left: 0px; */
    
}

.footer-nav-container {
    flex: 1;
    display: flex;
    padding: 32px 120px 64px 120px;
    justify-content: center;
    align-items: flex-end;
    gap: 120px;
    align-self: stretch;
}

.footer-nav-copyright-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.footer-nav-copyright-logo {
    width: 218px;
    height: 60px;
}

.footer-nav-copyright-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-nav-links-container {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.footer-nav-links-sitemap-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.footer-nav-links-sitemap-option-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.footer-nav-links-sitemap-option-container :hover {
    color: var(--brand);
}

.footer-nav-link-others-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.footer-nav-contact-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.footer-nav-social-links-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.footer-nav-social-links {
    width: 32px;
    height: 32px;
}