:root {
    --brand: #0084FF;
    --darker: #005DB5;
    --lighter-1: #81C2FF;
    --lighter-2: #BFE0FF;
    --lighter-3: #EBF5FF;
    --primary: #404040;
    --secondary: #7A7A7A;
    --white: #FAFCFF;
    --light-linear: linear-gradient( #81C2FF, #FAFCFF);
    --dark-linear: linear-gradient(#0084FF 0%, #404040 100%);
    --white-to-white: linear-gradient(#FFF, #FAFCFF);
}

@font-face {
    font-family: "Fallback Sans-serif";
    src: local(Arial);
    font-weight: 200;
}

@font-face {
    font-family: "Fallback Serif";
    src: local("Georgia");
    /* font-weight: 400; */
}

.mega-1 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 72px; */
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.heading-1 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 40px; */
    font-size: 2.78vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.heading-2 {
    font-family: "Book Antiqua", "Fallback Serif", serif;
    /* font-size: 32px; */
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
}

.heading-3 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 32px; */
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.heading-4 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 32px; */
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.heading-5 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 16px; */
    font-size: 1.11vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.body-1 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 24px; */
    font-size: 1.67vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.body-2 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 20px; */
    font-size: 1.39vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.body-3 {
    font-family: "Myriad Pro", "Fallback Sans-serif", sans-serif;
    /* font-size: 16px; */
    font-size: 1.11vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.brand-fill {
    background-color: var(--brand);
}

.darker-fill {
    background-color: var(--darker);
}

.lighter-fill {
    background-color: var(--lighter);
}

.lighter-1-fill {
    background-color: var(--lighter-1);
}

.lighter-2-fill {
    background-color: var(--lighter-2);
}

.lighter-3-fill {
    background-color: var(--lighter-3);
}

.primary-fill {
    background-color: var(--primary);
}

.secondary-fill {
    background-color: var(--secondary);
}

.white-fill {
    background-color: var(--white);
}

.light-linear-fill {
    background: var(--light-linear);
}

.dark-linear-fill {
    background: var(--dark-linear);
}

.white-to-white-fill {
    background: var(--white-to-white);
}

.brand-text {
    color: var(--brand);
}

.darker-text {
    color: var(--darker);
}

.lighter-text {
    color: var(--lighter);
}

.lighter-1-text {
    color: var(--lighter-1);
}

.lighter-2-text {
    color: var(--lighter-2);
}

.lighter-3-text {
    color: var(--lighter-3);
}

.primary-text {
    color: var(--primary);
}

.secondary-text {
    color: var(--secondary);
}

.white-text {
    color: var(--white);
}

.plain-link {
    text-decoration: none;
    /* color: inherit; */
}

.round-corner-8 {
    border-radius: 8px;
}

.round-corner-12 {
    border-radius: 12px;
}

.round-corner-16 {
    border-radius: 16px;
}

.round-corner-20 {
    border-radius: 20px;
}

.round-corner-40 {
    border-radius: 40px;
}

.round-corner-60 {
    border-radius: 60px;
}

.round-corner-200 {
    border-radius: 200px;
}

.no-wrap {
    white-space: nowrap;
}

.full-width {
    width: 100%;
    height: auto;
}

.align-stretch {
    align-self: stretch;
}

.text-center {
    text-align: center;
}

.text-justified {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.single-lighter-3-border {
    border: 1px solid var(--lighter-3);
}

.double-brand-border {
    border: 2px solid var(--brand);
}

.box-shadow-05 {
    box-shadow: 0px 0px 20px 0px rgba(64, 64, 64, 0.05);
}

.box-shadow-10 {
    box-shadow: 0px 0px 20px 0px rgba(64, 64, 64, 0.10);
}

.hide-overflow {
    overflow: hidden;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.flex {
    display: flex;
}

.flex-vertical {
	flex-direction: column;
}

.flex-vertical-reverse {
    flex-direction: column-reverse;
}

.flex-1 {
    flex: 1;
}

.relative {
    position: relative;
}

.bottom {
    position: absolute;
    bottom: 0px;
}

.flex-start {
    align-items: flex-start;
}

.flex-end {
    align-items: flex-end;
}

.clickable {
	cursor: pointer;
	user-select: none;
	pointer-events: all;
}

.non-clickable {
	cursor: default;
	pointer-events: none;
	user-select: none;
}

.button {
    transition: background-color 0.3s ease, border 0.3s ease;
}

.button.brand-fill {
    border: 2px solid transparent;
}

.button.brand-fill:hover {
    background-color: var(--darker);
    border: 2px solid var(--darker);
}

.button.white-fill:hover {
    background-color: var(--brand);
}

.button.white-fill {
    transition: color 0.3s ease;
}

.button.white-fill:hover * {
    color: var(--white);
}

.button.lighter-2-fill:hover {
    background-color: var(--lighter-1);
}

.card {
    transition: border 0.3s ease;
}

.card:hover {
    border: 1px solid var(--brand);
    box-shadow: 0px 0px 20px 0px rgba(64, 64, 64, 0.10);
}
