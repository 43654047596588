.services-page-search-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    position: relative;
    z-index: 2;
}


.services-page-search-bar {
    display: flex;
    padding: 16px 16px 16px 32px;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.search-icon {
    position: absolute;
    right: 16px;
}