.client-container {
    display: flex;
    padding: 0px 120px 64px 120px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.client-section-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.client-logos-container {
    display: flex;
    padding: 0px 120px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.client-logo {
    width: 48px;
    height: 48px;
    border-radius: 8px;
}

