.nav-bar-container {
    display: flex;
    max-width: 100%;
    padding: 16px 120px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.nav-pages-container {
    /* position: absolute;
    top: 50%;
    left: 50%; */
    display: flex;
    align-items: center;
    gap: 64px;
}

.nav-pages-hover * {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-pages-no-hover * {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-pages-hover :hover {
    color: var(--brand);
}

.nav-bar-buttons{
    display: flex;
    align-items: center;
    gap: 16px;
}

.signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-button {
    width: 24px;
    height: 24px;
}